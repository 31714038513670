import {api} from "../";

class CompanyRoles {
  constructor() {
    this.baseUrl = '/api/trade/company-roles';
  }
  async getCompanyRoles() {
    const response = await api.get(`${this.baseUrl}/get-company-roles`);

    return response.data;
  }

  async updateActiveRole(id, updatedData){
    const response =  await api.put(`${this.baseUrl}/${id}`, updatedData);

    return response.data;
  }
}

export const companyRolesCalls = new CompanyRoles();
