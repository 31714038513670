import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { apiConfig } from "../config.js";
import { AuthProvider } from "../contexts/auth/auth-google";
import { ACCESS_TOKEN_KEY } from "../store/const";
import {paths} from "../paths.js";

class Api {
  constructor() {
    this.baseUrl = apiConfig.apiUrl;
    this.client = axios.create({
      baseURL: this.baseUrl,
    });

    this.client.interceptors.request.use(this.handleRequestInterceptor);
    this.client.interceptors.response.use(
      (response) => response,
      this.handleResponseInterceptor
    );
  }

  handleRequestInterceptor = async (config) => {
    let token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token !== null) {
      const { exp } = jwtDecode(token);
      if (exp * 1000 < Date.now()) {
        token = await AuthProvider.refreshToken();
        if (token === null) {
          return Promise.reject(new Error('Authentication failed'));
        }
      }
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json';
    }

    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    return config;
  };

  handleResponseInterceptor = async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await AuthProvider.refreshToken();

        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axios(originalRequest);
        } else {
          return Promise.reject(new Error('Token refresh failed'));
        }
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        return Promise.reject(refreshError);
      }
    }
    if (error.response.status === 403) {
      window.location.href = paths.forbidden;
    }

    // if (error.response.status === 404) {
    //   window.location.href = paths.notFound;
    // }

    return Promise.reject(error);
  };

  async get(url, config = {}) {
    return this.client.get(url, config);
  }

  async post(url, data, config = {}) {
    return this.client.post(url, data, config);
  }

  async put(url, data, config = {}) {
    return this.client.put(url, data, config);
  }

  async delete(url, config = {}) {
    return this.client.delete(url, config);
  }
}

export const api = new Api();
