import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    items: [],
};

export const scanInventoryItemSlice = createSlice({
    name: 'scanInventoryItem',
    initialState,
    reducers: {

        addItem: (state, action) => {
            const newItem = action.payload;
            const index = state.items.findIndex(item => item.id === newItem.id);
            if (index === -1) {
                state.items.push(newItem);
            } else {
                state.items[index] = newItem;
            }
        },

        addItems: (state, action) => {
            state.items = action.payload;
        },

        deleteItem(state, action) {
            state.items = state.items.filter((item) => item.id !== action.payload);
        },

        deleteAllItems(state, action) {
            state.items = [];
        },

    },
});

export const {
  deleteAllItems,
  deleteItem,
  addItem,
  addItems
} = scanInventoryItemSlice.actions;

export const selectInventoryItems = state => state.scanInventoryItem.items;

export const reducer = scanInventoryItemSlice.reducer;
