import {useCallback, useEffect, useRef} from 'react';

export const useMessagesScroll = (thread) => {
    const messagesRef = useRef(null);

    const handleUpdate = useCallback(() => {
        if (!thread) {
            return;
        }

        if (!messagesRef.current) {
            return;
        }

        const container = messagesRef.current;
        const scrollElement = container.getScrollElement();

        if (scrollElement) {
            scrollElement.scrollTop = container.el.scrollHeight;
        }
    }, [thread]);

    useEffect(
        () => {
            handleUpdate();
        },
        [thread]
    );

    return {
        messagesRef,
    };
};
