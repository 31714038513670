import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import {DashboardFooter} from "../layouts/dashboard/dashboard-footer";

const OverviewPage = lazy(() => import('src/pages/dashboard/index'));

// Academy
const AcademyDashboardPage = lazy(() => import('src/pages/dashboard/academy/dashboard'));
const AcademyCoursePage = lazy(() => import('src/pages/dashboard/academy/course'));

// Blog
const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));
const BlogPostCreatePage = lazy(() => import('src/pages/dashboard/blog/create'));

// Customer
const CustomerListPage = lazy(() => import('src/pages/dashboard/customers/list'));
const CustomerDetailPage = lazy(() => import('src/pages/dashboard/customers/detail'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customers/edit'));

//data-management
const DataManagerImportBatchPage = lazy(() => import('src/pages/dashboard/data-management/import-batch'));
const DataManagerItnPage = lazy(() => import('src/pages/dashboard/data-management/batch-items'));
const DataManagerListingPage = lazy(() => import('src/pages/dashboard/data-management/listing'));
const DataManagerCreateFormPage = lazy(() => import('src/pages/dashboard/data-management/create-inventory'));
const DataManagerCreateProductPage = lazy(() => import('src/pages/dashboard/data-management/create-product'));
const DataManagerUpdateProductPage = lazy(() => import('src/pages/dashboard/data-management/update-product'));
const DataManagerProductListPage = lazy(() => import('src/pages/dashboard/data-management/product-list'));
const DataManagerScanInventoryItemPage = lazy(() => import('src/pages/dashboard/data-management/scan-inventory'));
const DataManagerDescribeInventoryItemPage = lazy(() => import('src/pages/dashboard/data-management/describe-inventory'));
const DataManagerPrintInventoryItemPage = lazy(() => import('src/pages/dashboard/data-management/print-inventory-label'));
const DataManagerDashboardInventoryPage = lazy(() => import('src/pages/dashboard/data-management/dashboard'));


const CompanyAccountPage = lazy(() => import('src/pages/dashboard/company-account'));

// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

// Job
const JobBrowsePage = lazy(() => import('src/pages/dashboard/jobs/browse'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/jobs/create'));
const CompanyDetailPage = lazy(() => import('src/pages/dashboard/jobs/companies/detail'));

// Logistics
const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard/logistics/dashboard'));
const LogisticsFleetPage = lazy(() => import('src/pages/dashboard/logistics/fleet'));

// Order
const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));

// Product
const ProductListPage = lazy(() => import('src/pages/dashboard/products/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/products/create'));

// Social
const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const CryptoPage = lazy(() => import('src/pages/dashboard/crypto'));
const EcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));

//addItem
const AddItem = lazy(() => import('src/pages/dashboard/add-item/add-item'))

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
        <>
          <DashboardLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayout>
          <DashboardFooter />
        </>
    ),
    children: [
      {
        index: true,
        element: <OverviewPage />,
      },
      {
        path: 'academy',
        children: [
          {
            index: true,
            element: <AcademyDashboardPage />,
          },
          {
            path: 'courses',
            children: [
              {
                path: ':courseId',
                element: <AcademyCoursePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: <BlogPostListPage />,
          },
          {
            path: 'create',
            element: <BlogPostCreatePage />,
          },
          {
            path: ':postId',
            element: <BlogPostDetailPage />,
          },
        ],
      },
      {
        path: 'data-management',
        children: [

        ]
      },
      {
        path: 'customers',
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: ':customerId',
            element: <CustomerDetailPage />,
          },
          {
            path: ':customerId/edit',
            element: <CustomerEditPage />,
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />,
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />,
          },
        ],
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <JobBrowsePage />,
          },
          {
            path: 'create',
            element: <JobCreatePage />,
          },
          {
            path: 'companies',
            children: [
              {
                path: ':companyId',
                element: <CompanyDetailPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'logistics',
        children: [
          {
            index: true,
            element: <LogisticsDashboardPage />,
          },
          {
            path: 'fleet',
            element: <LogisticsFleetPage />,
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <OrderListPage />,
          },
          {
            path: ':orderId',
            element: <OrderDetailPage />,
          },
        ],
      },
      {
        path: 'products',
        children: [
          {
            index: true,
            element: <ProductListPage />,
          },
          {
            path: 'create',
            element: <ProductCreatePage />,
          },
        ],
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeedPage />,
          },
          {
            path: 'profile',
            element: <SocialProfilePage />,
          },
        ],
      },
      {
        path: 'account',
        element: <AccountPage />,
      },
      {
        path: 'company-account',
        element: <CompanyAccountPage />,
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />,
      },
      {
        path: 'blank',
        element: <BlankPage />,
      },
      {
        path: 'calendar',
        element: <CalendarPage />,
      },
      {
        path: 'chat',
        element: <ChatPage />,
      },
      {
        path: 'crypto',
        element: <CryptoPage />,
      },
      {
        path: 'ecommerce',
        element: <EcommercePage />,
      },
      {
        path: 'file-manager',
        element: <FileManagerPage />,
      },
      {
        path: 'kanban',
        element: <KanbanPage />,
      },
      {
        path: 'mail',
        element: <MailPage />,
      },
      {
        path:'import-batches',
        element: <DataManagerImportBatchPage/>,
      },
      {
        path:'item/:batchId',
        element: <DataManagerItnPage/>,
      },
      {
        path:'listing',
        element: <DataManagerListingPage/>
      },
      {
        path: ':batchId/update-inventory',
        element: <DataManagerCreateFormPage/>
      },
      {
        path: 'add-item',
        element: <AddItem />,
      },
      {
        path: ':batchItemId/create-product',
        element: <DataManagerCreateProductPage/>
      },
      {
        path: ':productId/update-product',
        element: <DataManagerUpdateProductPage/>
      },
      {
        path: 'product/list',
        element: <DataManagerProductListPage/>
      },
      {
        path: 'inventory-info',
        element: <DataManagerDashboardInventoryPage/>
      },
      {
        path: 'scan-inventory',
        element: <DataManagerScanInventoryItemPage/>
      },
      {
        path: 'describe-inventory',
        element: <DataManagerDescribeInventoryItemPage/>
      },
      {
        path: 'print-inventory-label',
        element: <DataManagerPrintInventoryItemPage/>
      },
    ],
  },
];
