import {useAuth} from "../../hooks/use-auth.js";
import {useThread} from "../../hooks/ebay-chat/use-thread.js";

export const ChatInitializer = () => {
    const { includeRoles } = useAuth();
    const isAdmin = includeRoles(['ROLE_COMPANY_ADMIN']) && !includeRoles(['ROLE_SUPER_ADMIN']);

    useThread({ shouldFetchThread: isAdmin });

    return null;
};
