const ScanInventoryItemsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path d="M3 3h3v3H3z"/>
    <path d="M18 3h3v3h-3z"/>
    <path d="M3 18h3v3H3z"/>
    <path d="M18 18h3v3h-3z"/>
    <rect x="7" y="11" width="10" height="2"/>
    <line x1="11" y1="13" x2="11" y2="19"/>
    <line x1="13" y1="13" x2="13" y2="19"/>
  </svg>
);

export default ScanInventoryItemsIcon;
