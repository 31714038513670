const ProductListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path d="M8 2h8v20H8z"/>
    <path d="M12 2v20"/>
    <path d="M16 6H8"/>
    <path d="M16 10H8"/>
    <path d="M16 14H8"/>
    <path d="M16 18H8"/>
  </svg>
);
export default ProductListIcon;
