import {api} from "../";

class HashApi {
  constructor() {
    this.baseUrl = '/api/trade/hash';
  }

  async findInventoryImageHashByUser(request) {
    const response = await api.post(`${this.baseUrl}`, request);
    return response.data;
  }
}

export const hashApi = new HashApi();
