import {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useAuth} from 'src/hooks/use-auth';
import {useRouter} from 'src/hooks/use-router';
import {paths} from 'src/paths';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {RouterLink} from "../components/router-link.jsx";
import useMediaQuery from "@mui/material/useMediaQuery";

export const RoleBasedGuard = (props) => {
    const {children, roles} = props;
    const {isAuthenticated, includeRoles} = useAuth();
    const router = useRouter();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const checkAccess = useCallback(() => {
        if (!isAuthenticated) {
            router.replace(paths.auth.google.login);
        }
    }, [isAuthenticated, router]);

    useEffect(() => {
        checkAccess();
    }, []);

    if (!includeRoles(roles)) {
        return (
            <>
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        py: '80px',
                    }}
                >
                    <Container maxWidth="lg">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 6,
                            }}
                        >
                            <Box
                                alt="Not authorized"
                                component="img"
                                src="/assets/errors/error-403.png"
                                sx={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: 400,
                                }}
                            />
                        </Box>
                        <Typography
                            align="center"
                            variant={mdUp ? 'h1' : 'h4'}
                        >
                            403: Forbidden
                        </Typography>
                        <Typography
                            align="center"
                            color="text.secondary"
                            sx={{mt: 0.5}}
                        >
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 6,
                            }}
                        >
                            <Button
                                component={RouterLink}
                                href={paths.index}
                            >
                                Back to Home
                            </Button>
                        </Box>
                    </Container>
                </Box>
            </>
        );
    }

    return <>
        {children}
    </>;
};

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.array,
};
