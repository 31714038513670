import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import {usePopover} from 'src/hooks/use-popover';

import {TenantPopover} from './tenant-popover';
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "../../../store";
import {thunks} from "../../../thunks/company";
import Link from "@mui/material/Link";
import {paths} from "../../../paths";
import {RouterLink} from "../../../components/router-link";

const useTenants = () => {
  const dispatch = useDispatch();

  const handleCompanyGet = useCallback(() => {
    dispatch(thunks.getCompanies());
  }, [dispatch]);

  useEffect(
    () => {
      handleCompanyGet();
    },
    []
  );
  return useSelector(state => state.companyRoles.companies);
};

export const TenantSwitch = (props) => {
  const dispatch = useDispatch();
  const tenants = useTenants();
  const popover = usePopover();
  const currentCompany = useSelector(state => state.companyRoles.primaryCompany);
  const handleChangeActiveCompany = (companyId) => {

    dispatch(thunks.setActiveCompany(companyId));
    popover.handleClose();
  };

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        {...props}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Link component={RouterLink}
                href={paths.dashboard.companyAccount}
                sx={{ textDecoration: 'none', color: 'inherit', '&:hover': { textDecoration: 'none', color: 'inherit' } }}
          >
            <Typography
                color="inherit"
                variant="h6"
            >
              {currentCompany.companyName}
            </Typography>
          </Link>
          <Typography
            color="neutral.400"
            variant="body2"
          >
            Company
          </Typography>
        </Box>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon sx={{ fontSize: 16 }}>
            <ChevronDownIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={handleChangeActiveCompany}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={tenants}
      />
    </>
  );
};

TenantSwitch.propTypes = {
  sx: PropTypes.object,
};
