export const userRoles = [
    {
        description: 'Employee of company',
        title: 'Employee',
        value: 'ROLE_COMPANY_EMPLOYEE',
    },
    {
        description: 'Company Admin',
        title: 'Company Admin',
        value: 'ROLE_COMPANY_ADMIN',
    },
    {
        description: 'Full access',
        title: 'Admin',
        value: 'ROLE_SUPER_ADMIN',
    },
];

export const USER_ROLE_FOR_INVITE = {
    ROLE_COMPANY_EMPLOYEE: "Employee",
    ROLE_COMPANY_ADMIN: "Company Admin",
};

const roleMapping = {
    ROLE_COMPANY_EMPLOYEE: 'Company Employee',
    ROLE_COMPANY_ADMIN: 'Company Admin',
    ROLE_SUPER_ADMIN: 'Super Admin',
};

export const getReadableRoles = (roles) => {
    return roles.map((role) => roleMapping[role] || role).join(', ');
};

export const getReadableRoleInvitedUser = (role) => {
    return USER_ROLE_FOR_INVITE[role] || role;
};

export const roles = {
    ROLE_COMPANY_EMPLOYEE: "ROLE_COMPANY_EMPLOYEE",
    ROLE_COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
    ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ROLE_MARKETPLACE: "ROLE_MARKETPLACE",
};
