import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentProductItem: null,
};

export const productItemSlice = createSlice({
  name: 'productItem',
  initialState,
  reducers: {

    setCurrentProductItem: (state, action) => {
      const [item] = action.payload;
      state.currentProductItem = item;
    },
    clearCurrentProductItem: (state) => {
      state.currentProductItem = null;
    }

  },
});

export const {setCurrentProductItem,clearCurrentProductItem } = productItemSlice.actions;

export const selectCurrentProductItem = state => state.productItem.currentProductItem;

export const reducer = productItemSlice.reducer;
