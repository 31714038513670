export const tokens = {
  common: {
    languageChanged: 'common.languageChanged',
  },
  nav: {
    academy: 'nav.academy',
    account: 'nav.account',
    companyAccount: 'nav.companyAccount',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    blog: 'nav.blog',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    ecommerce: 'nav.ecommerce',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    verifyCode: 'nav.verifyCode',
    dataManagerImportBatch: 'nav.importBatch',
    dataManager:'nav.dataManager',
    dataManagerItn: 'nav.productItn',
    dataManagerListing: 'nav.productListing',
    dataManagerCreate:'nav.productCreate',
    dataManagerDescribeInventory:'nav.describeInventory',
    dataManagerTakePhoto:'nav.dataManagerTakePhoto',
    dataManagerProduct:'nav.productList',
    dataManagerDashboardInvInfo:'nav.dataManagerDashboardInvInfo',
    scanInventoryItem:'nav.scanInventoryItem',
    addItem: 'nav.addItem',
    mainFlow: 'nav.mainFlow',
    information: 'nav.information',
    privacyPolicy: 'nav.privacyPolicy',
    termsService: 'nav.termsService',
    aboutUs: 'nav.aboutUs',
    faq: 'nav.faq',
    admin: 'nav.admin',
    marketplace: 'nav.marketplace',
    marketplaceImport: 'nav.marketplaceImport',
    marketplaceBatchItems: 'nav.marketplaceBatchItems',
    marketplaceDescribeInventory: 'nav.marketplaceDescribeInventory',
    demoRequest: 'nav.demoRequest',
    emailDemoRequest: 'nav.emailDemoRequest',
    marketplaceProduct: 'nav.marketplaceProduct',
    adminProducts: 'nav.adminProducts',
    adminOffers: 'nav.adminOffers',
    clarityList: 'nav.clarityList',
    adminUserList: 'nav.adminUserList',
    adminSellerList: 'nav.adminSellerList',
    adminChat: 'nav.adminChat'
  },
};
