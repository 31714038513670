import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: [],
}

const reducers = {
  setLocations(state, action) {
    state.locations = action.payload;
  },

  removeLocation(state, action) {
    state.locations = state.locations.filter(location => location.merchantLocationKey !== action.payload);
  },
}

export const slice = createSlice({
  name: 'ebayLocations',
  initialState,
  reducers,
})

export const { reducer } = slice;
