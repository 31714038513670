import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Logo } from 'src/components/logo';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';

const sections = [
    {
        title: 'Contact',
        items: [
            {
                title: 'Contact Us',
                path: paths.contactUs,
            },
        ],
    },
    {
        title: 'Legal',
        items: [
            {
                title: 'Privacy Policy',
                path: paths.privacyPolicy,
            },
            {
                title: 'Terms of Service',
                path: paths.termsService,
            },
            {
                title: 'About Us',
                path: paths.aboutUs,
            },
            {
                title: 'FAQ',
                path: paths.faq,
            },
        ],
    }
];

const SIDE_NAV_WIDTH = 280;

const FooterRoot = styled(Box)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: SIDE_NAV_WIDTH,
    },
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
    },
    width: {
        lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
    },
}));

const currentYear = new Date().getFullYear();

export const DashboardFooter = (props) => (

    <FooterRoot {...props}>
        <Container maxWidth="lg"
                   sx={{ pb: 3 }}>
            <Grid container
                  spacing={3}
                  sx={{ mt: 2, my: 1 }}>
                <Grid
                    xs={12}
                    sm={4}
                    md={3}
                    sx={{
                        order: {
                            xs: 4,
                            md: 1,
                        },
                    }}
                >
                    <Stack spacing={1}>
                        <Stack
                            alignItems="center"
                            component={RouterLink}
                            direction="row"
                            display="inline-flex"
                            href={paths.index}
                            spacing={1}
                            sx={{ textDecoration: 'none' }}
                        >
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    height: 24,
                                    width: 24,
                                }}
                            >
                                <Logo />
                            </Box>
                            <Box
                                sx={{
                                    color: 'text.primary',
                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                    fontSize: 14,
                                    fontWeight: 800,
                                    letterSpacing: '0.3px',
                                    lineHeight: 2.5,
                                    '& span': {
                                        color: 'primary.main',
                                    },
                                }}
                            >
                                Seller Sync <span>PRO</span>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                {sections.map((section, index) => (
                    <Grid
                        key={section.title}
                        xs={12}
                        sm={4}
                        md={3}
                        sx={{
                            order: {
                                md: index + 2,
                                xs: index + 1,
                            },
                        }}
                    >
                        <Typography color="text.secondary"
                                    variant="overline">
                            {section.title}
                        </Typography>
                        <Stack
                            component="ul"
                            spacing={1}
                            sx={{
                                listStyle: 'none',
                                m: 0,
                                p: 0,
                            }}
                        >
                            {section.items.map((item) => {
                                const linkProps = item.path
                                    ? item.external
                                        ? {
                                            component: 'a',
                                            href: item.path,
                                            target: '_blank',
                                        }
                                        : {
                                            component: RouterLink,
                                            href: item.path,
                                        }
                                    : {};

                                return (
                                    <Stack alignItems="center"
                                           direction="row"
                                           key={item.title}
                                           spacing={2}>
                                        <Box
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                height: 2,
                                                width: 12,
                                            }}
                                        />
                                        <Link color="text.primary"
                                              variant="subtitle2"
                                              {...linkProps}>
                                            {item.title}
                                        </Link>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Grid>
                ))}
            </Grid>
            <Divider />
            <Typography color="text.secondary"
                        variant="caption"
                        sx={{mr: 2}}
            >
                © {currentYear}
            </Typography>
            <Typography color="text.secondary"
                        variant="caption">
                All Rights Reserved.
            </Typography>
        </Container>
    </FooterRoot>
);
