import {createSlice} from '@reduxjs/toolkit';
import {objFromArray} from 'src/utils/obj-from-array';

const initialState = {
  contacts: {
    byId: {},
    allIds: [],
  },
  currentThreadId: undefined,
  threads: [],
  pagination: {
    page: 0,
    hasMore: true
  },
  query: undefined,
};

const reducers = {
  getThreads(state, action) {
    const { items, page, hasMore } = action.payload;

    if (page === 0) {
      state.threads = items;
    } else {
      state.threads = [...state.threads, ...items];
    }

    state.pagination.page = page;
    state.pagination.hasMore = hasMore;
  },
  getThread(state, action) {
    const thread = action.payload;

    if (thread) {
      const existingThreadIndex = state.threads.findIndex(t => t.id === thread.id);

      if (existingThreadIndex !== -1) {
        state.threads[existingThreadIndex] = thread;
      } else {
        state.threads.unshift(thread);
      }
    }
  },

  markThreadAsSeen(state, action) {
    const threadHash = action.payload;
    const thread = state.threads.find((thread) => thread.hash === threadHash);

    if (thread) {
      thread.unreadCount = 0;
    }
  },

  setCurrentThread(state, action) {
    state.currentThreadId = action.payload;
  },
  addMessage(state, action) {
    const { threadId, message } = action.payload;
    const thread = state.threads.find((t) => t.hash === threadId);
    if (thread) {
      thread.messages.push(message);
    } else {
      console.error(`Тема с ID ${threadId} не найдена.`);
    }
  },

};

export const slice = createSlice({
  name: 'adminChat',
  initialState,
  reducers,
});

export const { reducer } = slice;
