import {useCallback, useEffect, useRef, useState} from 'react';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import {useAuth} from "../../hooks/use-auth.js";
import {ChatButton} from "./chat-button.jsx";
import {Scrollbar} from "../scrollbar.jsx";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "../../store/index.js";
import {useMessagesScroll} from "../../hooks/admin-chat/use-messages-scroll.js";
import {EbayChatMessages} from "../../sections/dashboard/ebay-chat/chat-messages.jsx";
import {EbayChatMessageAdd} from "../../sections/dashboard/ebay-chat/chat-message-add.jsx";
import {thunks} from "../../thunks/ebay/chat.js";
import {ChatTooltipButton} from "./chat-tooltip-button.jsx";
import {ChatInitializer} from "./chat-initializer.jsx";


export const ChatWidget = () => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const {isAuthenticated, includeRoles} = useAuth();
    const isAdmin = includeRoles(['ROLE_COMPANY_ADMIN']) && !includeRoles(['ROLE_SUPER_ADMIN']);
    const thread = useSelector((state) => state.ebayChat.thread);
    const {messagesRef} = useMessagesScroll(thread);

    const scrollOnOpenRef = useRef(null);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
        if (thread) {
            dispatch(thunks.markAsRead(thread.hash));
        }
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleSend = useCallback(
        async (body) => {
            try {
                await dispatch(
                    thunks.addMessage({
                        threadId: thread.hash,
                        body,
                    })
                );
            } catch (err) {
                console.error(err);
            }
        },
        [dispatch, thread]
    );

    useEffect(() => {
        if (openDrawer) {
            setTimeout(() => {
                if (scrollOnOpenRef.current) {
                    const container = scrollOnOpenRef.current.getScrollElement();
                    if (container) {
                        container.scrollTop = container.scrollHeight;
                    }
                }
            }, 0);
        }
    }, [openDrawer]);

    useEffect(() => {
        if (openDrawer && thread) {
            dispatch(thunks.markAsRead(thread.hash));
        }
    }, [dispatch, thread, openDrawer]);

    if (!isAuthenticated || !isAdmin) {
        return null;
    }

    return (
        <>
            <ChatInitializer />
            <ChatTooltipButton />
            <ChatButton onClick={handleDrawerOpen} />
            <Drawer
                disableScrollLock
                anchor="right"
                onClose={handleDrawerClose}
                open={openDrawer}
                ModalProps={{
                    BackdropProps: {
                        invisible: true,
                    },
                    sx: {zIndex: 1400},
                }}
                PaperProps={{
                    elevation: 24,
                    sx: {
                        width: {
                            xs: '100%',
                            sm: '70%',
                            md: '40%',
                        },
                    },
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                    sx={{
                        px: 3,
                        pt: 2,
                    }}
                >
                    <Typography variant="h6">Chat with support team</Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <IconButton
                            color="inherit"
                            onClick={handleDrawerClose}
                        >
                            <SvgIcon>
                                <XIcon />
                            </SvgIcon>
                        </IconButton>
                    </Stack>
                </Stack>
                <Divider />
                <Box
                    spacing={5}
                    sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <Scrollbar
                        ref={(el) => {
                            messagesRef.current = el;
                            scrollOnOpenRef.current = el;
                        }}
                        sx={{maxHeight: '100%'}}
                    >
                        <EbayChatMessages
                            messages={thread?.messages || []}
                            participants={thread?.participants || []}
                        />
                    </Scrollbar>
                </Box>
                <Divider />
                <EbayChatMessageAdd
                    onSend={handleSend}
                />
            </Drawer>
        </>
    );
};
