import {createContext} from 'react';
import {Issuer} from 'src/utils/auth';

export const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    token: '',
};

export const AuthContext = createContext({
    ...initialState,
    issuer: Issuer.Google,
    signInWithGoogleAuth: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
    refreshToken: () => Promise.resolve(),
    registerUser: () => Promise.resolve(),
    registerUserAfterConfirmation: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    includeRoles: (roles) => false,
});
