import {api} from "../index.js";

class EbayChatApi {
  constructor() {
    this.baseUrl = '/api/trade/chat';
  }

  async getThread() {
    const response = await api.get(`${this.baseUrl}`);
    return response.data;
  }

  async addThread() {
    const response = await api.post(`${this.baseUrl}`, null);
    return response.data;
  }

  async addMessage(request) {
    const {threadId, body} = request;
    const response = await api.post(`${this.baseUrl}/${threadId}`, {message: body});
    return response.data;
  }

  async markAsRead(threadHash) {
    const response = await api.post(`${this.baseUrl}/mark-read/${threadHash}`, null);
    return response.data;
  }
}

export const ebayChatCalls = new EbayChatApi();
