import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  thread: undefined,
  unreadCount: 0,
  loading: false,
};

const reducers = {
  getThread(state, action) {
    state.thread = action.payload;
    state.unreadCount = action.payload.unreadCount;
  },

  incrementUnreadCount(state) {
    state.unreadCount += 1;
  },

  clearUnreadCount(state, action) {
    if (state.thread && state.thread.hash === action.payload) {
      state.unreadCount = 0;
    }
  },

  addMessage(state, action) {
    const { message, threadId } = action.payload;

    if (state.thread && state.thread.hash === threadId) {
      state.thread.messages.push(message);
    } else {
      console.warn("Message received for a different thread or no thread available");
    }
  },

};

export const slice = createSlice({
  name: 'ebayChat',
  initialState,
  reducers,
});

export const { reducer } = slice;
