import React, { useState, useEffect } from 'react';
import { Button, Typography, useTheme } from '@mui/material';

const CookiePopup = () => {
    const theme = useTheme();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const acceptedCookies = localStorage.getItem('acceptedCookies');
        if (!acceptedCookies) {
            setIsVisible(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('acceptedCookies', 'true');
        setIsVisible(false);
    };

    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            padding: '10px',
            zIndex: 9999,
            display: isVisible ? 'flex' : 'none',
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            boxSizing: 'border-box'
        }}>
        <Typography variant="h5" color="inherit" sx={{mt: 3}} style={{ marginBottom: '20px' }}>
                We use cookies to improve your experience on our site. Please accept our cookie policy.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={acceptCookies}
            >
                Accept
            </Button>
        </div>
    );
};

export default CookiePopup;
