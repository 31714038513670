import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conditions: [],
  selectedCondition: undefined,
}

const reducers = {
  setConditions(state, action) {
    const { conditions } = action.payload;
    state.conditions = conditions;
  },


  setSelectedCondition(state, action) {
    state.selectedCondition = action.payload;
  }
}

export const slice = createSlice({
  name: 'ebayConditions',
  initialState,
  reducers,
})

export const { reducer } = slice;
