import { slice } from '../slices/mobile-url-component';
import { hashApi } from "../api/hash";
import { paths } from 'src/paths';

export const thunks = {
  fetchUrl: (companyId) => async (dispatch, getState) => {
    try {
      const { url } = getState().mobilePhotoUrl;
      if (!url) {
        dispatch(slice.actions.setLoading(true));
        const response = await hashApi.findInventoryImageHashByUser({companyId: companyId});
        const newUrl = `https://${window.location.host}${paths.takeInventoryPhoto(response.hash)}`;
        dispatch(slice.actions.setUrl({ url: newUrl }));
        dispatch(slice.actions.setLoading(false));
      }
    } catch (error) {
      console.error('[API Mobile url fetch error]: fetch error', error);
      dispatch(slice.actions.setLoading(false));
    }
  }
};
