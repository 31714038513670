export const paths = {
    index: '/',
    checkout: '/checkout',
    pricing: '/pricing',
    privacyPolicy: '/policy',
    termsService: '/terms-service',
    faq: '/faq',
    requestDemo: '/request-demo',
    successDemo: '/success-demo',
    successSentConfirmationEmail: '/success-confirmation-email',
    contactUs: '/contact',
    successContactUs: '/success-contact-us',
    aboutUs: '/about-us',
    authDeclined: '/auth-declined',
    authSuccess: '/auth-success',
    invitationEmployee: (hash) => `/invitation/${hash}`,
    takeInventoryPhoto: (hash) => `/take-inventory-photo/${hash}`,
    confirmationEmail: (hash) => `/confirmation-email/${hash}`,
    resendConfirmationEmail: (hash) => `/resend-confirmation-email/${hash}`,
    guidedListingImage: '/guided-listing-images',
    auth: {
        auth0: {
            callback: '/auth/auth0/callback',
            login: '/auth/auth0/login',
        },
        jwt: {
            login: '/auth/jwt/login',
            register: '/auth/jwt/register',
        },
        firebase: {
            login: '/auth/firebase/login',
            register: '/auth/firebase/register',
        },
        amplify: {
            confirmRegister: '/auth/amplify/confirm-register',
            forgotPassword: '/auth/amplify/forgot-password',
            login: '/auth/amplify/login',
            register: '/auth/amplify/register',
            resetPassword: '/auth/amplify/reset-password',
        },
        google: {
          login: '/auth/google/login',
          register: '/auth/google/data-user-register-for-login',
          registerForm:'/auth/google/data-user-register-for-login/form',
          forgotPassword: '/auth/google/data-user-forgot-password',
          resetPassword: '/auth/google/reset-pwd'
        }
    },
    authDemo: {
        forgotPassword: {
            classic: '/auth-demo/forgot-password/classic',
            modern: '/auth-demo/forgot-password/modern',
        },
        login: {
            classic: '/auth-demo/login/classic',
            modern: '/auth-demo/login/modern',
        },
        register: {
            classic: '/auth-demo/register/classic',
            modern: '/auth-demo/register/modern',
        },
        resetPassword: {
            classic: '/auth-demo/reset-password/classic',
            modern: '/auth-demo/reset-password/modern',
        },
        verifyCode: {
            classic: '/auth-demo/verify-code/classic',
            modern: '/auth-demo/verify-code/modern',
        },
    },
    dashboard: {
        index: '/dashboard/inventory-info',
        academy: {
            index: '/dashboard/academy',
            courseDetails: '/dashboard/academy/courses/:courseId',
        },
        invoice: '/dashboard/import-batches',
        batchId: (batchId) => `/dashboard/item/${batchId}`,
        listing: '/dashboard/listing',
        updateInv: (batchId) => `/dashboard/${batchId}/update-inventory`,
        createProduct: (batchItemId) => `/dashboard/${batchItemId}/create-product`,
        updateProduct: (productId) => `/dashboard/${productId}/update-product`,
        productList: '/dashboard/product/list',
        scanInventoryItem: '/dashboard/scan-inventory',
        describeInventoryItem: '/dashboard/describe-inventory',
        printInventoryItem: '/dashboard/print-inventory-label',
        addItem: '/dashboard/add-item',
        inventoryInfo: '/dashboard/inventory-info',
        account: '/dashboard/account',
        companyAccount: '/dashboard/company-account',
        analytics: '/dashboard/analytics',
        blank: '/dashboard/blank',
        information: {
            privacyPolicy: '/policy',
            termsService: '/terms-service',
            aboutUs: '/about-us',
            faq: '/faq',
        },
        blog: {
            index: '/dashboard/blog',
            postDetails: '/dashboard/blog/:postId',
            postCreate: '/dashboard/blog/create',
        },
        calendar: '/dashboard/calendar',
        chat: '/dashboard/chat',
        crypto: '/dashboard/crypto',
        customers: {
            index: '/dashboard/customers',
            details: '/dashboard/customers/:customerId',
            edit: '/dashboard/customers/:customerId/edit',
        },
        ecommerce: '/dashboard/ecommerce',
        fileManager: '/dashboard/file-manager',
        invoices: {
            index: '/dashboard/invoices',
            details: '/dashboard/invoices/:orderId',
        },
        jobs: {
            index: '/dashboard/jobs',
            create: '/dashboard/jobs/create',
            companies: {
                details: '/dashboard/jobs/companies/:companyId',
            },
        },
        kanban: '/dashboard/kanban',
        logistics: {
            index: '/dashboard/logistics',
            fleet: '/dashboard/logistics/fleet',
        },
        mail: '/dashboard/mail',
        orders: {
            index: '/dashboard/orders',
            details: '/dashboard/orders/:orderId',
        },
        products: {
            index: '/dashboard/products',
            create: '/dashboard/products/create',
        },
        social: {
            index: '/dashboard/social',
            profile: '/dashboard/social/profile',
            feed: '/dashboard/social/feed',
        },
    },
    admin: {
        demoRequestsDashboard: '/admin/demo-requests',
        emailDemoRequestsDashboard: '/admin/email-demo-requests',
        productList: '/admin/product-list',
        createProduct: '/admin/product/create',
        offerList: '/admin/offer-list',
        clarityList: '/admin/clarity-list',
        userList: '/admin/user-list',
        sellerList: '/admin/seller-list',
        updateProduct: (productId) => `/admin/${productId}/update-product`,
        ebayListingByUser: (userId) => `/admin/ebay-listing/list/${userId}`,
        batchItemByUser: (userId) => `/admin/batch-item/list/${userId}`,
        chat: '/admin/chat',
    },
    marketplace: {
        import: '/marketplace/import',
        batchId: (batchId) => `/marketplace/item/${batchId}`,
        updateInv: (inventoryId) => `/marketplace/${inventoryId}/update-inventory`,
        describeInventoryItem: '/marketplace/describe-inventory',
        productList: '/marketplace/product/list',
        updateProduct: (productId) => `/marketplace/${productId}/update-product`,
        createProduct: '/marketplace/product/create',
    },
    components: {
        index: '/components',
        dataDisplay: {
            detailLists: '/components/data-display/detail-lists',
            tables: '/components/data-display/tables',
            quickStats: '/components/data-display/quick-stats',
        },
        lists: {
            groupedLists: '/components/lists/grouped-lists',
            gridLists: '/components/lists/grid-lists',
        },
        forms: '/components/forms',
        modals: '/components/modals',
        charts: '/components/charts',
        buttons: '/components/buttons',
        typography: '/components/typography',
        colors: '/components/colors',
        inputs: '/components/inputs',
    },
    docs: 'https://material-kit-pro-react-docs.devias.io',
    notAuthorized: '/401',
    notFound: '/404',
    forbidden: '/403',
    serverError: '/500',
    invalidLink: '409',
};
