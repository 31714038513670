import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentProductItem: null,
};

export const adminMarketplaceProductItemSlice = createSlice({
  name: 'adminMarketplaceProductItem',
  initialState,
  reducers: {

    setAdminCurrentMarketplaceProductItem: (state, action) => {
      const [item] = action.payload;
      state.currentProductItem = item;
    },
    clearCurrentProductItem: (state) => {
      state.currentProductItem = null;
    }

  },
});

export const {setAdminCurrentMarketplaceProductItem, clearCurrentProductItem } = adminMarketplaceProductItemSlice.actions;

export const selectAdminCurrentMarketplaceProductItem = state => state.adminMarketplaceProductItem.currentProductItem;

export const reducer = adminMarketplaceProductItemSlice.reducer;
