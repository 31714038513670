import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

import {Layout as DashboardLayout} from 'src/layouts/dashboard';
import {DashboardFooter} from "../layouts/dashboard/dashboard-footer";
import {RoleBasedGuard} from "../guards/role-based-guard.jsx";
import {roles} from "../utils/user-roles.js";

const MarketplaceImportProductsPage = lazy(() => import('src/pages/marketplace/import'));
const MarketplaceBatchItemListPage = lazy(() => import('src/pages/marketplace/batch-item-list'));
const MarketplaceUpdateInventoryItemPage = lazy(() => import('src/pages/marketplace/update-inventory'));
const MarketplaceDescribeInventoryItemPage = lazy(() => import('src/pages/marketplace/describe-inventory-item'));
const MarketplaceProductPage = lazy(() => import('src/pages/marketplace/product-list'));
const MarketplaceCreateProductPage = lazy(() => import('src/pages/marketplace/create-product'));
const MarketplaceUpdateProductPage = lazy(() => import('src/pages/marketplace/update-product'));

export const marketplaceRoutes = [
    {
        path: 'marketplace',
        element: (
            <>
                <RoleBasedGuard roles={[roles.ROLE_SUPER_ADMIN, roles.ROLE_MARKETPLACE]}>
                    <DashboardLayout>
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                    <DashboardFooter />
                </RoleBasedGuard>
            </>
        ),
        children: [
            {
                path: 'import',
                element: <MarketplaceImportProductsPage />
            },
            {
                path:'item/:batchId',
                element: <MarketplaceBatchItemListPage/>,
            },
            {
                path: ':inventoryId/update-inventory',
                element: <MarketplaceUpdateInventoryItemPage/>
            },
            {
                path: 'describe-inventory',
                element: <MarketplaceDescribeInventoryItemPage/>
            },
            {
                path: 'product/list',
                element: <MarketplaceProductPage/>
            },
            {
                path: ':productId/update-product',
                element: <MarketplaceUpdateProductPage/>
            },
            {
                path: 'product/create',
                element: <MarketplaceCreateProductPage/>
            },
        ],
    },
];
