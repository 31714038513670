import {companyRolesCalls} from "../api/company-roles";
import { slice } from 'src/slices/company-roles';
import {paths} from "../paths.js";

export const thunks = {
  getCompanies: () => async (dispatch, getState) => {
    const { companyRoles } = getState();

    if (companyRoles.companies.length === 0) {
      try {
        const data = await companyRolesCalls.getCompanyRoles();
        dispatch(slice.actions.setCompanies(data));
        const primaryCompany = data.find(company => company.isPrimary === true || company.isPrimary === 1);

        if (primaryCompany) {
          dispatch(slice.actions.setPrimaryCompany(primaryCompany));
        } else {
          console.error("No primary company found");
        }
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    }
  },

  setActiveCompany: (newActiveCompanyId) => async (dispatch, getState) => {
    try {
      const updatedData = {
        isPrimary: 1
      };
      await companyRolesCalls.updateActiveRole( newActiveCompanyId, updatedData);
      const companies = getState().companyRoles.companies;
      const updatedCompanies = companies.map(company =>
          company.id === newActiveCompanyId
              ? { ...company, isPrimary: 1 }
              : { ...company, isPrimary: 0 }
      );
      dispatch(slice.actions.setCompanies(updatedCompanies));
      dispatch(slice.actions.setPrimaryCompany(updatedCompanies.find(company => company.isPrimary)));
    } catch (error) {
      console.error("Failed to update active company", error);
    } finally {
      window.location.reload();
      window.location.href = paths.dashboard.index;
    }
  }
};
