import {useCallback, useEffect} from 'react';
import {useDispatch} from "../../store/index.js";
import {thunks} from "../../thunks/ebay/chat.js";

export const useThread = ({ shouldFetchThread }) => {
  const dispatch = useDispatch();

  const handleThreadGet = useCallback(() => {
    dispatch(thunks.getThread());
  }, [dispatch]);

  useEffect(
      () => {
          if (shouldFetchThread) {
              handleThreadGet();
          }
      },
      []
  );
}
