import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: '',
  isLoading: false,
}

const reducers = {
  setUrl(state, action) {
    const { url } = action.payload;
    state.url = url;
  },
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
};

export const slice = createSlice({
  name: 'mobilePhotoUrl',
  initialState,
  reducers,
})

export const { reducer } = slice;
