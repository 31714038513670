import {slice} from 'src/slices/ebay/chat';
import {ebayChatCalls} from "../../api/ebay-chat/index.js";


const getThread = () => async (dispatch) => {
    const response = await ebayChatCalls.getThread();
    if (response) {
        return dispatch(slice.actions.getThread(response));
    }
    const newResponse = await ebayChatCalls.addThread();
    dispatch(slice.actions.getThread(newResponse));
};

const markAsRead = (threadHash) => async (dispatch) => {
    await ebayChatCalls.markAsRead(threadHash);
    dispatch(slice.actions.clearUnreadCount(threadHash));
};

const addMessage = (params) => async (dispatch) => {
    const response = await ebayChatCalls.addMessage(params);
    dispatch(slice.actions.addMessage({ threadId: params.threadId, message: response }));
};


export const thunks = {
    addMessage,
    getThread,
    markAsRead,
};
