export const ROLE_HIERARCHY = [
  { key: 'ROLE_USER', value: [] },
  { key: 'ROLE_COMPANY_EMPLOYEE', value: ['ROLE_USER'] },
  { key: 'ROLE_COMPANY_ADMIN', value: ['ROLE_COMPANY_EMPLOYEE'] },
  { key: 'ROLE_SUPER_ADMIN', value: ['ROLE_COMPANY_ADMIN'] },
];

export const calcAllAllowedRoles = (roles) =>
  roles
    .map((role) => findNestedRoles(role))
    .reduce((prev, current) => [...prev, ...current], [])
    .filter((elem, index, self) => index === self.indexOf(elem));

export const findNestedRoles = (role) =>
  (ROLE_HIERARCHY.find((item) => role === item.key)?.value || [])
    .map((subRole) => [subRole, ...findNestedRoles(subRole)])
    .reduce((prev, current) => [...prev, ...current], [role]);
