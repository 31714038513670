import PropTypes from 'prop-types';
import MessageChatSquareIcon from '@untitled-ui/icons-react/build/esm/MessageChatSquare';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import {useSelector} from 'src/store';


export const ChatButton = (props) => {
    const unreadCount = useSelector((state) => state.ebayChat.unreadCount);

    return (
        <Tooltip title="Need Help? Click and open chat!"
                 placement="top"
                 arrow>
            <Box
                sx={{
                    position: 'fixed',
                    backgroundColor: 'background.paper',
                    borderRadius: '50%',
                    bottom: (theme) => theme.spacing(10),
                    boxShadow: 16,
                    margin: (theme) => theme.spacing(4),
                    right: 0,
                    zIndex: (theme) => theme.zIndex.speedDial,
                }}
                {...props}
            >
                <ButtonBase
                    sx={{
                        backgroundColor: 'warning.main',
                        borderRadius: '50%',
                        color: 'primary.contrastText',
                        p: '10px',
                        position: 'relative',
                    }}
                >
                    <SvgIcon>
                        <MessageChatSquareIcon />
                    </SvgIcon>
                    {unreadCount > 0 && (
                        <span
                            style={{
                                position: 'absolute',
                                top: -5,
                                right: -5,
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                color: 'white',
                                padding: '5px',
                                fontSize: '12px',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {unreadCount}
                        </span>
                    )}
                </ButtonBase>
            </Box>
        </Tooltip>
    );
};

ChatButton.propTypes = {
    onClick: PropTypes.func,
};
