import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  selectedCategory: null,
  isLoading: false
}

const reducers = {
  setCategories(state, action) {
    const { categories } = action.payload;
    state.categories = categories;
  },

  setSelectedCategory(state, action) {
    state.selectedCategory = action.payload;
  },

  clearCategories(state) {
   state.selectedCategory = undefined;
   state.categories = [];
  },
  setIsLoadingCategories(state, action) {
    state.isLoading = action.payload;
  },
}

export const slice = createSlice({
  name: 'ebayCategories',
  initialState,
  reducers,
})
export const  getCategories = (state) => state.ebayCategory.categories;
export const  getSelected = (state) => state.ebayCategory.selectedCategory;

export const getIsLoadingCategory = (state) => state.ebayCategory.isLoading;
export const { reducer } = slice;
