import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    companies: [],
    primaryCompany: {
        id: null,
        companyId: null,
        companyName: '',
        isPrimary: null,
        createdAt: null,
        sellerName: null,
        sellers: [],
        users: [],
    },
};

const reducers = {
    setCompanies(state, action) {
        const newCompanies = action.payload;
        const companyMap = new Map(state.companies.map(company => [company.id, company]));

        newCompanies.forEach(company => {
            companyMap.set(company.id, company);
        });


        state.companies = Array.from(companyMap.values());
    },

    setPrimaryCompany(state, action) {
        const company = action.payload;
        state.primaryCompany.id = company.id;
        state.primaryCompany.companyId = company.companyId;
        state.primaryCompany.companyName = company.name;
        state.primaryCompany.isPrimary = company.isPrimary;
        state.primaryCompany.sellers = company.sellers;
        state.primaryCompany.users = company.users;
        state.primaryCompany.createdAt = company.createdAt;
        state.primaryCompany.sellerName = company.sellerName;
    },

    updatePrimaryCompanyName(state, action) {
        const newName = action.payload;
        if (state.primaryCompany) {
            state.primaryCompany.companyName = newName;
        }
    },

    unlinkSeller(state, action) {
        const sellerName = action.payload;
        if (state.primaryCompany && state.primaryCompany.sellers) {
            state.primaryCompany.sellers = state.primaryCompany.sellers.filter(
                seller => seller.sellerName !== sellerName
            );
        }
        if (state.primaryCompany) {
            state.primaryCompany.sellerName = null;
        }
    },

    setSellerName(state, action) {
        const newName = action.payload;
        if (state.primaryCompany) {
            state.primaryCompany.sellerName = newName;
        }
    },

    removeUserById(state, action) {
        const userIdToRemove = action.payload;
        if (state.primaryCompany && state.primaryCompany.users) {
            state.primaryCompany.users = state.primaryCompany.users.filter(user => user.id !== userIdToRemove);
        }
    },

    updateUserRole(state, action) {
        const { userId, newRole } = action.payload;

        if (state.primaryCompany && state.primaryCompany.users) {
            const userIndex = state.primaryCompany.users.findIndex(user => user.id === userId);

            if (userIndex !== -1) {
                state.primaryCompany.users[userIndex].roles = newRole;
            }
        }
    },

};

export const slice = createSlice({
    name: 'company',
    initialState,
    reducers,
});

export const {reducer} = slice;

