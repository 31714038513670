import toast, { ToastBar, Toaster as HotToaster } from 'react-hot-toast';
import { alpha } from '@mui/system/colorManipulator';
import { useTheme } from '@mui/material/styles';

export const Toaster = () => {
    const theme = useTheme();

    return (
        <HotToaster
            position="top-right"
            toastOptions={{
                style: {
                    backdropFilter: 'blur(6px)',
                    background: alpha(theme.palette.neutral[900], 0.8),
                    color: theme.palette.common.white,
                    boxShadow: theme.shadows[16],
                    fontSize: '24px',
                    width: '500px',
                    minHeight: '100px',
                    maxHeight: '90%',
                    padding: '16px',
                    overflow: 'auto',
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <div onClick={() => toast.dismiss(t.id)}
                             style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {icon}
                                {message}
                            </div>
                        </div>
                    )}
                </ToastBar>
            )}
        </HotToaster>
    );
};
