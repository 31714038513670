import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allAttributes: [],
  selectedAttributes: [],
  filteredAttributes:[],
  isLoadedAttributes: true,
  errorStatus: {},
};

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    setAttributes: (state, action) => {
      state.allAttributes = action.payload;
    },
    clearAttributes: (state) => {
      state.allAttributes = [];
      state.selectedAttributes = [];
      state.filteredAttributes = [];
      state.isLoadedAttributes = true;
      state.errorStatus = {};
    },
    setSelectedAttributes: (state, action) => {
        state.selectedAttributes = { ...state.selectedPolicies, ...action.payload };
      },
    setFilteredAttributes: (state, action) => {
      state.filteredAttributes = action.payload;
  },
    setLoading:(state, action) => {
      state.isLoadedAttributes = action.payload;
    },

    setErrorStatus: (state, action) => {
      state.errorStatus = action.payload;
    },
    setError: (state, action) => {
      const { attrName, error } = action.payload;
      state.errorStatus[attrName] = error;
    },
    removeError: (state, action) => {
      const { attrName } = action.payload;
      delete state.errorStatus[attrName];
    },
}

});

export const {
  setAttributes,
  clearAttributes,
  setSelectedAttributes ,
  setFilteredAttributes,
  setLoading,
  setErrorStatus,
 setError,
  removeError,
} = attributesSlice.actions;

export const selectAllAttributes = (state) => state.productAttributes.allAttributes;
export const getSelectedAttributes = (state) => state.productAttributes.selectedAttributes;
export const selectFilteredAttributes = (state) => state.productAttributes.filteredAttributes;
export const getIsLoading = (state) => state.productAttributes.isLoadedAttributes;
export const getErrors = (state) => state.productAttributes.errorStatus;

export const reducer = attributesSlice.reducer;
