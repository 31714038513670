import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  currentItems: {
    batchItem: null,
    items: [],
    itemsDetails: [],
    currentInvItem: null,
  },
};

export const inventoryItemsSlice = createSlice({
  name: 'inventoryItems',
  initialState,
  reducers: {

    addInventoryItem: (state, action) => {
      state.items.push(action.payload);
    },

    removeInventoryItem: (state, action) => {
      state.items = state.items.filter(item => item.id !== action.payload);
    },

    setCurrentInventoryItems: (state, action) => {
      const [batchItem, items, itemsDetails, currentInvItem] = action.payload;
      state.currentItems.batchItem = batchItem;
      state.currentItems.items = items;
      state.currentItems.itemsDetails = itemsDetails;
      state.currentItems.currentInvItem = currentInvItem;
    },

    setInventoryItems: (state, action) => {
      const [items, item] = action.payload;
      state.currentItems.items = items;
      state.currentItems.currentInvItem = item;
    },

    setCurrentInventoryItem: (state, action) => {
      const [item] = action.payload;
      state.currentItems.currentInvItem = item;
    },

  },
});

export const { addInventoryItem, removeInventoryItem, setCurrentInventoryItems, setInventoryItems, setCurrentInventoryItem } = inventoryItemsSlice.actions;

export const selectInventoryItems = state => state.inventoryItems.items;
export const selectCurrentItem = state => state.inventoryItems.currentItems;

export const reducer = inventoryItemsSlice.reducer;
