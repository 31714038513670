import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: undefined,
  companyName: undefined,
  invitationId: undefined,
}

const reducers = {
  setInvitationData(state, action) {
    const { email, companyName, invitationId } = action.payload;
    state.email = email;
    state.companyName = companyName;
    state.invitationId = invitationId;
  },
}

export const slice = createSlice({
  name: 'invitation',
  initialState,
  reducers,
})

export const { reducer } = slice;
