import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentProductItem: null,
};

export const marketplaceProductItemSlice = createSlice({
  name: 'marketplaceProductItem',
  initialState,
  reducers: {

    setCurrentMarketplaceProductItem: (state, action) => {
      const [item] = action.payload;
      state.currentProductItem = item;
    },
    clearCurrentProductItem: (state) => {
      state.currentProductItem = null;
    }

  },
});

export const {setCurrentMarketplaceProductItem, clearCurrentProductItem } = marketplaceProductItemSlice.actions;

export const selectCurrentMarketplaceProductItem = state => state.marketplaceProductItem.currentProductItem;

export const reducer = marketplaceProductItemSlice.reducer;
