import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import XIcon from "@untitled-ui/icons-react/build/esm/X.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useState} from "react";


export const ChatTooltipButton = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [showHeader, setShowHeader] = useState(true);

    if (!showHeader) return null;

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                borderRadius: 1,
                bottom: (theme) => theme.spacing(17),
                boxShadow: 16,
                margin: (theme) => theme.spacing(4),
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                right: 0,
                p: 2,
                zIndex: (theme) => theme.zIndex.speedDial,
                width: isMobile ? '40%' : '15%',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: -20,
                    transform: 'translateX(-50%)',
                    left: '50%',
                }}
            >
                <Avatar src='/assets/support.svg' />
            </Box>

            <Typography
                variant="body2"
                sx={{ flexGrow: 1, textAlign: 'center', mt:2 }}
            >
                Got any questions? I'm happy to help.
            </Typography>

            <IconButton
                sx={{ ml: 1 }}
                onClick={() => setShowHeader(false)}
            >
                <SvgIcon>
                    <XIcon />
                </SvgIcon>
            </IconButton>
        </Box>
    );
};
