import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  categoryOptions: [],
  allCategory: [],
  currentCategory: null,
  categoryBreadcrumbs: [],
}
export const marketplaceCategorySlice = createSlice({
    name: 'marketplaceCategory',
    initialState,
    reducers: {
      setCategoryOptions: (state, action) => {
        state.categoryOptions = action.payload;
      },
      setCategoryAll: (state, action) => {
        state.allCategory = action.payload;
      },
      setCurrentCategory: (state, action) => {
        state.currentCategory = action.payload;
      },
      clearCurrentCategory: (state) => {
        state.currentCategory = null;
      },
      setCategoryBreadcrumbs: (state, action) => {
        state.categoryBreadcrumbs = action.payload;
      },
      clearCategoryBreadcrumbs: (state) => {
        state.categoryBreadcrumbs = [];
      },
    },
  })
;

export const {
  setCategoryOptions,
  setCurrentCategory,
  setCategoryAll,
  clearCurrentCategory,
  setCategoryBreadcrumbs,
  clearCategoryBreadcrumbs,
} = marketplaceCategorySlice.actions;
export const selectCategoryOptions = (state) => state.marketplaceCategory.categoryOptions;
export const selectCurrentCategory = (state) => state.marketplaceCategory.currentCategory;
export const selectCategoryBreadcrumbs = (state) => state.marketplaceCategory.categoryBreadcrumbs;

export const selectAllCategories = (state) => state.marketplaceCategory.allCategory;


export const reducer = marketplaceCategorySlice.reducer;
