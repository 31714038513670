import Cookies from 'js-cookie';
import {userPropertyCalls} from "../api/user-property/index.js";

export const sendClarityIdToBackend = async () => {
    try {
        const clarityCookie = Cookies.get('_clck');

        if (clarityCookie) {
            const clarityId = clarityCookie.split('|')[0];
            await userPropertyCalls.saveClarityId({clarityId: clarityId});
        }
    } catch (error) {
        console.error('Error send Clarity ID:', error);
    }
};
