import {api} from "../";

class UserPropertyApi {
  constructor() {
    this.baseUrl = '/api/trade/user-property';
  }

  async saveClarityId(request){
    const response =  await api.post(`${this.baseUrl}/clarity`, request);
    return response.data;
  }
}

export const userPropertyCalls = new UserPropertyApi();
