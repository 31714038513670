import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as MarketingLayout } from 'src/layouts/marketing';

import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { componentsRoutes } from './components';
import { dashboardRoutes } from './dashboard';
import {adminRoutes} from "./admin.jsx";
import {marketplaceRoutes} from "./marketplace.jsx";

const Error401Page = lazy(() => import('src/pages/401'));
const Error404Page = lazy(() => import('src/pages/404'));
const Error403Page = lazy(() => import('src/pages/403'));
const Error500Page = lazy(() => import('src/pages/500'));
const Error409Page = lazy(() => import('src/pages/token-expired'));
const SuccessPage = lazy(() => import('src/pages/success-page'));

const HomePage = lazy(() => import('src/pages/index'));
const CheckoutPage = lazy(() => import('src/pages/checkout'));
const PricingPage = lazy(() => import('src/pages/pricing'));

const PrivacyPolicyPage = lazy(() => import('src/pages/footer/privacy-policy'));
const AboutUsPage = lazy(() => import('src/pages/footer/about-us'));
const FaqPage = lazy(() => import('src/pages/footer/faq'));
const RequestDemoPage = lazy(() => import('src/pages/demo'));
const SuccessDemoPage = lazy(() => import('src/pages/success-demo'));
const SuccessConfirmationEmailPage = lazy(() => import('src/pages/success-confirmation-email'));
const TermsServicePage = lazy(() => import('src/pages/footer/terms-service'));
const ContactUsPage = lazy(() => import('src/pages/footer/contact-us'));
const SuccessContactUsPage = lazy(() => import('src/pages/footer/success-contact-us'));

const AuthDeclinedPage = lazy(() => import('src/pages/auth-declined'));

const InventoryPhotoUploaderPage = lazy(() => import('src/pages/dashboard/data-management/take-photo'));
const InvitationEmployeePage = lazy(() => import('src/pages/invitation-employee/invitation-employee'));
const ConfirmationEmailPage = lazy(() => import('src/pages/confirmation-email/confirmation-email'));
const ResendConfirmationEmailPage = lazy(() => import('src/pages/confirmation-email/resend-confirmation-email'));
const GuidedListingImagesPage = lazy(() => import('src/pages/guided-listing'));


export const routes = [
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'pricing',
        element: <PricingPage />,
      },
      {
        path: 'policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'about-us',
        element: <AboutUsPage />,
      },
      {
        path: 'faq',
        element: <FaqPage />,
      },
      ...componentsRoutes,
    ],
  },
  ...authRoutes,
  ...authDemoRoutes,
  ...dashboardRoutes,
  ...adminRoutes,
  ...marketplaceRoutes,
  {
    path: 'checkout',
    element: <CheckoutPage />,
  },
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '403',
    element: <Error403Page />,
  },
  {
    path: '409',
    element: <Error409Page />,
  },
  {
    path: 'request-demo',
    element: <RequestDemoPage />,
  },
  {
    path: 'terms-service',
    element: <TermsServicePage />,
  },
  {
    path: 'success-demo',
    element: <SuccessDemoPage />,
  },
  {
    path: 'success-confirmation-email',
    element: <SuccessConfirmationEmailPage />,
  },
  {
    path: 'contact',
    element: <ContactUsPage />,
  },
  {
    path: 'success-contact-us',
    element: <SuccessContactUsPage />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path:'auth-success',
    element: <SuccessPage/>,
  },
  {
    path: 'auth-declined',
    element: <AuthDeclinedPage />,
  },
  {
    path: 'take-inventory-photo/:hash',
    element: <InventoryPhotoUploaderPage/>
  },
  {
    path: 'invitation/:hash',
    element: <InvitationEmployeePage />
  },
  {
    path: 'confirmation-email/:hash',
    element: <ConfirmationEmailPage />
  },
  {
    path: 'resend-confirmation-email/:hash',
    element: <ResendConfirmationEmailPage />
  },
  {
    path: 'guided-listing-images',
    element: <GuidedListingImagesPage />
  },
  {
    path: '*',
    element: <Error404Page />,
  },
];
