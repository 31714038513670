import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  returnPolicy: {},
  fulfillmentPolicy: {},
  paymentPolicy: {},
  selectedPolicies: {},
  missingPolicy: '',
}

const reducers = {
  setPolicies(state, action) {
    const { returnPolicy, fulfillmentPolicy, paymentPolicy } = action.payload;
    state.returnPolicy = returnPolicy;
    state.fulfillmentPolicy = fulfillmentPolicy;
    state.paymentPolicy = paymentPolicy;
  },

  setSelectedPolicies(state, action) {
    state.selectedPolicies = { ...state.selectedPolicies, ...action.payload };
  },

  setMissingPolicyError(state, action) {
    state.missingPolicy = action.payload;
  },

  setClearPolicy(state) {
    state.returnPolicy = {};
    state.fulfillmentPolicy = {};
    state.paymentPolicy = {};
    state.selectedPolicies = {};
    state.missingPolicy = '';
  }
}

export const slice = createSlice({
  name: 'ebayPolicies',
  initialState,
  reducers,
})

export const  {
  setPolicies,
  setClearPolicy,
  setSelectedPolicies,
  setMissingPolicyError
} = slice.actions;

export const { reducer } = slice;
