import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  selectedCountry: undefined,
}

const reducers = {
  setCountries(state, action) {
    const { countries } = action.payload;
    state.countries = countries;
  },


  setSelectedCountry(state, action) {
    state.selectedCountry = action.payload;
  }
}

export const slice = createSlice({
  name: 'countries',
  initialState,
  reducers,
})

export const { reducer } = slice;
