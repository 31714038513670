import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentBatchItem: null,
  allBatchItems: {},
};

const batchItemSlice = createSlice({
  name: 'batchItem',
  initialState,
  reducers: {
    setCurrentBatchItem(state, action) {
      state.currentBatchItem = action.payload;
    },
    addOrUpdateBatchItem(state, action) {
      const batchItem = action.payload;
      state.allBatchItems[batchItem.id] = batchItem;
    },
    removeBatchItem(state, action) {
      const { id } = action.payload;
      delete state.allBatchItems[id];
      if (state.currentBatchItem?.id === id) {
        state.currentBatchItem = null;
      }
    },
  },
});

export const { setCurrentBatchItem, addOrUpdateBatchItem, removeBatchItem } = batchItemSlice.actions;

export const selectAllBatchItems = (state) => state.batchItems.allBatchItems;
export const selectCurrentBatchItem = (state) => state.batchItems.currentBatchItem;
export const reducer = batchItemSlice.reducer;
