import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import {EbayChatMessage} from './chat-message';
import {useAuth} from "../../../hooks/use-auth.js";


const getAuthor = (message, participants, currentUser, includeRoles) => {
  const participant = participants.find(
      (participant) => participant.id === message.user.id
  );
  const isMessageFromSuperAdmin = message.user.isSuperAdmin;
  const isCurrentUserSuperAdmin = includeRoles(['ROLE_SUPER_ADMIN']);
  const shouldBeOnRight = isCurrentUserSuperAdmin
      ? isMessageFromSuperAdmin
      : !isMessageFromSuperAdmin;

  if (isMessageFromSuperAdmin) {
    return {
      name: isCurrentUserSuperAdmin && participant ? participant?.name : 'Support Manager',
      avatar: '/assets/support.svg',
      isUser: shouldBeOnRight,
    };
  }

  return {
    avatar: participant?.photo ?? '',
    name: shouldBeOnRight ? 'me' : participant?.name,
    isUser: shouldBeOnRight,
  };
};



export const EbayChatMessages = (props) => {
  const { messages = [], participants = [], ...other } = props;
  const user = useAuth().user;
  const { includeRoles } = useAuth();

  return (
    <Stack
      spacing={2}
      sx={{ p: 3 }}
      {...other}
    >
      {messages.map((message) => {
        const author = getAuthor(message, participants, user, includeRoles);

        return (
          <EbayChatMessage
            authorAvatar={author.avatar}
            authorName={author.name}
            body={message.body}
            createdAt={message.createdAt}
            key={message.id}
            position={author.isUser ? 'right' : 'left'}
          />
        );
      })}
    </Stack>
  );
};

EbayChatMessages.propTypes = {
  messages: PropTypes.array,
  participants: PropTypes.array,
};
