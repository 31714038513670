import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

export const TenantPopover = (props) => {
  const { anchorEl, onChange, onClose, open = false, tenants, ...other } = props;
  const flatTenants = tenants.flat();
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 180 } }}
      {...other}
    >
      {flatTenants.length > 0 ? (
        flatTenants.map((tenant) => (
        <MenuItem
          key={tenant.id}
          onClick={() => onChange?.(tenant.id)}
        >
          {tenant.name}
        </MenuItem>
        ))
      ) : (
        <p>No tenants available.</p>
      )}
    </Popover>
  );
};

TenantPopover.propTypes = {
  anchorEl: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  tenants: PropTypes.array.isRequired,
};
