import { useEffect } from "react";
import { slice } from 'src/slices/ebay/chat';
import { useAuth } from "../../hooks/use-auth.js";
import {useDispatch, useSelector} from "../../store/index.js";
import {mercureConf} from "../../config.js";


export const ChatSubscriptions = () => {
  const thread = useSelector((state) => state.ebayChat.thread);
  const dispatch = useDispatch();
  const user = useAuth().user;

  useEffect(() => {
    if (!thread) return;

    const url = new URL(mercureConf.url);
    url.searchParams.append('topic', `chat/user/${thread.hash}`);

    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (user.id !== data.user.id) {
        dispatch(slice.actions.addMessage({ threadId: thread.hash, message: data }));
        dispatch(slice.actions.incrementUnreadCount());
      }
    };

    return () => {
      eventSource.close();
    };
  }, [thread, dispatch, user.id]);

  return null;
};
