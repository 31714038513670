import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  policies: [],
}

const reducers = {
  setDashboardPolicies(state, action) {
    state.policies = action.payload;
  },

  addPolicy(state, action) {
    state.policies.push(action.payload);
  },

  removePolicy(state, action) {
    const { type, id } = action.payload;
    state.policies = state.policies.filter(policy => {
      if (type === 'Return Policy' && policy.returnPolicyId) {
        return policy.returnPolicyId !== id;
      }
      if (type === 'Payment Policy' && policy.paymentPolicyId) {
        return policy.paymentPolicyId !== id;
      }
      if (type === 'Fulfillment Policy' && policy.fulfillmentPolicyId) {
        return policy.fulfillmentPolicyId !== id;
      }
      return true;
    });
  }
}

export const slice = createSlice({
  name: 'ebayPoliciesDashboard',
  initialState,
  reducers,
})

export const { reducer } = slice;
